import { createRouter as _createRouter, createWebHistory } from 'vue-router';

const routes = [
    //{ path: '/home', component: HomePage, name: 'homeDemo' },
    { path: '/guest/test/:id', component: () => import('@/pages/GuestTestPage.vue' /* webpackChunkName: "page-guest-test" */), name: 'guestTest' },

    { path: '/demo/theme', component: () => import('@/pages/DemoThemePage' /* webpackChunkName: "page-demo-theme" */), name: 'demoTheme', meta: {layout: 'AppLayoutUser'} },
    { path: '/quiz/:quiz_hash?', component: () => import('@/pages/QuizPage' /* webpackChunkName: "page-quiz" */), name: 'quiz', meta: {layout: 'AppLayoutUser'} },
    { path: '/quizzes', component: () => import('@/pages/QuizzesPage' /* webpackChunkName: "page-quizzes" */), name: 'quizzes' , meta: {layout: 'AppLayoutUser'} },
    { path: '/quizzes/rating', component: () => import('@/pages/QuizzesRatingPage' /* webpackChunkName: "page-quiz-rating" */), name: 'quizzesRating', meta: {layout: 'AppLayoutUser'} },
    { path: '/quizzes/materials', component: () => import('@/pages/QuizMaterialsPage' /* webpackChunkName: "page-quizzes-materials" */), name: 'quizzesMaterials', meta: {layout: 'AppLayoutUser'} },
    { path: '/quiz/training', component: () => import('@/pages/QuizTrainingPage' /* webpackChunkName: "page-quiz-training" */), name: 'quizTraining', meta: {layout: 'AppLayoutUser'} },
    { path: '/quiz/captain', component: () => import('@/pages/QuizCaptainPage' /* webpackChunkName: "page-quiz-captain" */), name: 'quizCaptain', meta: {layout: 'AppLayoutUser'} },
    { path: '/quiz/:quiz_hash/materials', component: () => import('@/pages/QuizMaterialsPage' /* webpackChunkName: "page-quiz-materials" */), name: 'quizMaterials', meta: {layout: 'AppLayoutUser'} },
    { path: '/game/:quiz_hash/:team_hash?', component: () => import('@/pages/QuizGamePage' /* webpackChunkName: "page-quiz-game" */), name: 'quizGame' },

    //{ path: '/auth', component: () => import('@/pages/AuthPage.vue' /* webpackChunkName: "page-auth" */), name: 'auth' },
    { path: '/auth', component: () => import('@/pages/KmmAuthPage.vue' /* webpackChunkName: "page-auth" */), name: 'auth' },
    { path: '/profile', component: () => import('@/pages/ProfilePage' /* webpackChunkName: "page-profile" */), name: 'profile', meta: {layout: 'AppLayoutUser'} },
    { path: '/', component: () => import('@/pages/HomePage' /* webpackChunkName: "page-home" */), name: 'home', meta: {layout: 'AppLayoutUser'} },
    { path: '/subjects', component: () => import('@/pages/SubjectsPage' /* webpackChunkName: "page-subjects" */), name: 'subjects', meta: {layout: 'AppLayoutUser'} },
    { path: '/subjects/:id', component: () => import('@/pages/SubjectPage' /* webpackChunkName: "page-subject" */), name: 'subject-id', meta: {layout: 'AppLayoutUser'} },
    { path: '/book/:id', component: () => import('@/pages/BookPage' /* webpackChunkName: "page-book" */), name: 'book', meta: {layout: 'AppLayoutUser'} },
    { path: '/topic/:id', component: () => import('@/pages/TopicPage' /* webpackChunkName: "page-topic" */), name: 'topic', meta: {layout: 'AppLayoutUser'} },
    { path: '/topic-new/:id', component: () => import('@/pages/TopicNewPage' /* webpackChunkName: "page-topic-new" */), name: 'topic-new', meta: {layout: 'AppLayoutUser'} },
    { path: '/journal', component: () => import('@/pages/JournalPage' /* webpackChunkName: "page-journal" */), name: 'journal', meta: {layout: 'AppLayoutUser'} },
    { path: '/craft/materials', component: () => import('@/pages/CraftMaterialsPage' /* webpackChunkName: "page-craft-materials" */), name: 'craftMaterials', meta: {layout: 'AppLayoutUser'} },
    { path: '/craft/journal', component: () => import('@/pages/CraftJournalPage' /* webpackChunkName: "page-craft-journal" */), name: 'craftJournal', meta: {layout: 'AppLayoutUser'} },
    { path: '/diplomas', component: () => import('@/pages/DiplomasPage' /* webpackChunkName: "page-diplomas" */), name: 'diplomas', meta: {layout: 'AppLayoutUser'} },

    { path: '/war-memory', component: () => import('@/pages/WarMemoryPage' /* webpackChunkName: "page-war-memory" */), name: 'warMemory' },
    { path: '/war-memory-info', component: () => import('@/pages/WarMemoryInfoPage' /* webpackChunkName: "page-war-memory-info" */), name: 'warMemoryInfo'/*, meta: {layout: 'AppLayoutUser'}*/ },

    { path: '/defense', component: () => import('@/pages/defense/DefensePage' /* webpackChunkName: "page-defense" */), name: 'defense'/*, meta: {layout: 'AppLayoutUser'}*/ },
    { path: '/module/:id', component: () => import('@/pages/defense/ModulePage' /* webpackChunkName: "page-module" */), name: 'module', meta: {layout: 'AppLayoutUser'} },
];

export function createRouter(app, pinia, redirectStore, requestStore) {
    const router = _createRouter({
        history: createWebHistory('/'), // @note Changed from: createWebHashHistory()
        routes,
    });

    router.beforeEach(async (to, from, next) => {
        const guestRouteNames = ['quizGame', 'guestTest']; // @todo Свести с логикой в App.vue!!!
        if (guestRouteNames.includes(to.name)) {
            next();
        }

        /*if (app.config.globalProperties.$store.accessKey) {
            to.path === '/subjects' && next('/subjects')
        } else {
            to.path !== '/auth' &&  next('/auth');
        }*/

        next();
    })

    return router;
}
