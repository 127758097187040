<script>
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'AppLayoutUser',
    mounted() {

    }
})
</script>

<template>
    <main class="wrapper">
        <kmm-nav-defense v-if="$store.isDefense" />
        <kmm-nav v-else />

        <slot />
    </main>
</template>
