<script>
export default {
    name: "AppNotificator",
    watch:{
        $route (curr, old) {
            if (!curr) return;

            const ymd = new Date().toISOString().split('T')[0];
            const isAnyQuizPage = ['quiz', 'quizzes', 'quizzesRating', 'quizTraining', 'quizMaterials', 'quizzesMaterials'].includes(curr.name);
            const notLessonPage = (isAnyQuizPage || curr.name === 'profile' || curr.name === 'craftMaterials');

            // @todo Need to implement notifications in the admin

            if (this.$store.me.is_teacher) {
                this.$notify.promoOnce('promoZu202409', 'Шановні колеги,<br> Вам доступна нова гра в Квіз "Школа Безпеки". Бажаємо успіхів.');
            }

            /*const noticeRefreshKey = 'notice-refresh-v4';
            if (!localStorage.getItem(noticeRefreshKey)) {
                this.$notify.promo('Вітаємо 👋<br>🗞 Функціонал кабінету оновлено.<br>Ми перезавантажимо сторінку, щоб ви користувались актуальною версією кабінету 🤗');
                //localStorage.setItem(noticeRefreshKey, 'true');
            }*/

            /*if (this.$store.me.is_teacher && new Date() < new Date('2023-12-15') && isAnyQuizPage) {
                this.$notify.promoOnce('bugQuizReplyDuplicate', `👋 Вітаємо, шановні вчителі! 🤗<br>Ми виявили та виправили баг у системі підрахунку балів 😬<br>Якщо Ваші команди підключались одночасно до одного й того ж QR-коду - бали цих команд ми перерахували 🧮<br>Просимо вибачення за незручності 🫣`);
            }*/

            //this.$notify.promoOnce('key' + ymd, `..`);
            //this.$notify.promoOnce('quizScoreUpdate', '🧮 Ми оновили систему розрахунку балів за Quiz.<br>Перегляди <a href="/quiz/training" style="color: #9f4c4c;">«Матеріалів для підготовки»</a> в кабінеті учня<br>приносять кожній команді додаткові бали.<br>Готуйтесь, грайте, перемагайте! 🤗')

            if (this.$store.me.is_teacher && !this.$store.me.school?.id && notLessonPage) {
                this.$notify?.promoOnce('doSchoolSelect'+ymd, `💌 Шановний вчителю,<br>вкажіть будь ласка свій навчальний заклад на сторінці 👉 «<a href="/profile">Профілю</a>»,<br>💖 щоб ми могли видавати Вам персональні сертифікати 📜<br>за участь у Quiz чи інші освітні заходи 🧩<br>🤗 Дякуємо за Вашу роботу!`);
            }

            if (this.$store.me.is_teacher && !this.$store.me.phone && notLessonPage) {
                this.$notify?.promoOnce('doPhoneEnter', `💌 Шановний вчителю,<br>вкажіть будь ласка свій номер телефону на сторінці 👉 «<a href="/profile">Профілю</a>»,<br>щоб ми мали змогу зконтактувати з Вами при необхідності<br>(вручення сертифікатів, призів за майстер-класи, тощо..)<br> 🤝 Дякуємо за Вашу роботу!`);
            }

            if (this.$store.me.is_teacher && [1, 2, 3, 4, 5].includes(parseInt(this.$store.me.course.level)) && new Date() < new Date('2024-02-29') && notLessonPage) {
                //this.$notify?.promoOnce('quizWinterNew', '👋 Вітаємо,<br>Доступний новий квіз на тему «Зима».<br>Бажаємо успіху! 🤝');
                this.$notify?.promoOnce('quizFebruary13', '👋 Вітаємо,<br>Доступний новий квіз на тему «Лютневий калейдоскоп».<br>Бажаємо успіху! 🤝');
            }

            if (this.$store.me.is_teacher && [2, 3, 4].includes(parseInt(this.$store.me.course.level)) && new Date() < new Date('2024-02-29') && notLessonPage) {
                this.$notify?.promoOnce('quizMaterialsCartoon2024', '👋 Вітаємо,<br>Вам та учням вже доступні <a href="/quiz/training" style="color: #9f4c4c;">«Матеріали для підготовки»</a> до наступного квізу на тему «Мультфільми і казки».<br>Готуйте Ваші команди та перемагайте! Бажаємо успіху! 🤝');
            }

            if (this.$store.me.is_student && [2, 3, 4].includes(parseInt(this.$store.me.course.level)) && new Date() < new Date('2024-02-29') && notLessonPage) {
                //this.$notify?.promoOnce('quizWinter2024', '👋 Вітаємо,<br>Вам доступні <a href="/quiz/training" style="color: #9f4c4c;">«Матеріали для підготовки»</a> до нового квізу на тему «Зима».<br>Готуйтесь та перемагайте! Бажаємо успіху! 🤝');
                this.$notify?.promoOnce('quizMaterialsCartoon2024', '👋 Вітаємо,<br>Вам доступні <a href="/quiz/training" style="color: #9f4c4c;">«Матеріали для підготовки»</a> до наступного квізу на тему «Мультфільми і казки».<br>Готуйтесь та перемагайте! Бажаємо успіху! 🤝');
            }

            /*if (new Date() < new Date('2024-09-01 07:00')) {
                this.$notify?.promoOnce('promo2september'+ymd, 'vimeo videos ?');
            }*/

            // 👋 🤏 🫶 🤝 🎈 🧩 🧣 🎓 🔔  🧮 📒📔📖📙📕📗📘📚 🔖 🪙 🏅 🏆 🥇 🥈 🥉
            // 👋 💌 💖 🤗 🤝 🤦 👈👉 👀 🍀 ☘ 🛟 ⏳ ⌚ ⏰ 🌨 ❄ ⛄ 🎁 🎀 📜 💼 🏁 🚩
        }
    },
}
</script>

<!--<template>
    <div></div>
</template>

<style scoped>

</style>-->
