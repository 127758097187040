export default function (app, {router}) {
    // app.axios

    // @see https://t.ly/VtURe
    router.afterEach((to, from) => {
        function freshnessCheck(minutes) {
            const key = 'freshnessTimestamp';
            const passedMinutes = parseInt((new Date().getTime() - new Date(parseInt(localStorage.getItem(key))).getTime()) / 1000 / 60);
            if (passedMinutes >= minutes || isNaN(passedMinutes)) {
                localStorage.setItem(key, new Date().getTime().toString());
                //const nocache = new Date().getTime();
                fetch(`${location.origin}/version.txt`).then(resp => resp.text()).then(actualVersion => {
                    console.log('freshness version:', process.env.VUE_APP_BUILD_VERSION, '===', actualVersion);
                    if (actualVersion !== process.env.VUE_APP_BUILD_VERSION) location.reload();
                });
            }
        }

        try {
            freshnessCheck(10);
        } catch (err) {
            console.warn(`Fail freshness!`, err);
        }
    })
}
